.footer{
    /* background-color: pink; */
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 10px;
    display: flex;
    font-size: 15px;
    text-align: right;
}

.footer .copyrightIcon{
    color: white;
}

.footer p{
    color: white;
    font-size: 14px;
    display: inline-block;
    /* background-color: yellow; */
    margin-left: 10px;
}

.footerInnerHi{
    /* background-color: pink; */
    width: 200px;
    text-align: left;
}

.footerInner{
    /* background-color: green; */
    width: 100%;
    padding-top: 13px;
}

.footer a{
    float:right;
    color: white;
    text-decoration: none;
    font-weight: 500;
    margin-right: 20px;
}

@media screen and (max-width: 550px){
    .footer{
        padding-left: 15px;
        padding-right: 10px;
    }
    .footer a{
        margin-right: 10px;
    }
}
