.navbar{
    /* background-color: rgb(59, 122, 224); */
    /* background-image: linear-gradient(to bottom, white, rgb(27, 37, 99)); */
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
    padding-bottom: 20px;
    display: flex;
    font-size: 15px;
    text-align: right;
    position: relative;
    z-index: 100;
}

.navResumeDiv{
    /* background-color: yellow; */
    float: right;
    margin-top: 15px;
    width: calc(100% - 40px);
}

.navLogoDiv{
    /* background-color: aquamarine; */
}

.navLink{
   text-decoration: none;
   color: white;
   padding: 5px;
   margin-right: 15px;
}

.navResume{
    text-decoration: none;
    padding: 8px;
    padding-left: 20px;
    padding-right: 20px;
    color: black;
    /* border: 1px solid white; */
    background-color: white;
    border-radius: 5px;
 }

 .whiteLogo{
    width: 50px;
 }

 @media screen and (max-width: 550px){
    .navbar{
        padding-left: 20px;
        padding-right: 20px;
    }
}