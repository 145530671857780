.aboutOuter{
    overflow-y: hidden;
}

.aboutWhole{
    overflow-y: hidden;
}

.about{
    margin-left: 15%;
    margin-right: 15%;
    position: relative;
    z-index: 50;
    overflow-y: hidden;
}

.colorGradient{
    /* background-color: pink; */
    position: absolute;
    top:0;
    left:0;
    bottom: 0;
    width: 100%;
    height: 200%;
}

.cloudContainerOne{
    opacity: .25;
    z-index: -100;
}
.cloudOne{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, transparent, rgb(43, 68, 130)), url("../cloud.png");
    animation: animate 200s linear infinite;
}
.cloudContainerTwo{
    opacity: .20;
    z-index: -100;
}
.cloudTwo{
    position: absolute;
    top:800px;
    left:0;
    width: 100%;
    height: 100%;
    /* background-image: radial-gradient(transparent, rgb(43, 68, 130)), url("../cloud.png"); */
    background-image: linear-gradient( rgb(43, 68, 130), transparent, rgb(43, 68, 130)), url("../cloud.png");
    animation: animate 200s linear infinite;
}

.cloudContainerThree{
    opacity: .20;
    z-index: -100;
}
.cloudThree{
    position: absolute;
    top:1700px;
    left:0;
    width: 100%;
    height: 100%;
    /* background-image: radial-gradient(transparent, rgb(43, 68, 130)), url("../cloud.png"); */
    background-image: linear-gradient( rgb(43, 68, 130), transparent, rgb(43, 68, 130)), url("../cloud.png");
    animation: animate 200s linear infinite;
}

.cloudContainerFour{
    opacity: .20;
    z-index: -100;
}
.cloudFour{
    display: none;
    position: absolute;
    top:2750px;
    left:0;
    width: 100%;
    height: 100%;
    /* background-image: radial-gradient(transparent, rgb(43, 68, 130)), url("../cloud.png"); */
    background-image: linear-gradient( rgb(43, 68, 130), transparent, rgb(43, 68, 130)), url("../cloud.png");
    animation: animate 200s linear infinite;
}

@keyframes animate {
    0%{
        background-position: 0px;
    }
    100%{
        background-position: 5440px;
    }
}

.aboutIntroFirst{
    width: 100%;
    margin-top: 40px;
    z-index: -50;
}

.aboutIntroSecond{
    width: 100%;
    z-index: -50;
}

.about h1{
    display: inline-block;
    margin-bottom: 0px;
    margin-top: 20px;
    font-size: 28px;
}

.about h2{
    color: white;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 35px;
}

.about h3{
    font-weight: 500;
    padding-right: 5px;
    font-size: 18px;
    display: inline-block;
    
}

.profileImage{
    width: 150px;
    border-radius: 50%;
}

.aboutIntroGreeting{
    color: white;
    font-weight: 500;
    /* display: inline-block; */
}

.aboutIntroName{
    font-weight: 700;
    color: white;
    font-size: 28px;
    display: inline-block;
    margin-left: 10px;
}

.aboutIntro{
    text-align: center;
    /* background-color: pink; */
    /* height: 575px; */
    height: max-content;
    margin-top: 25px;
    margin-bottom: 130px;
}

.aboutIntro p{
    color: rgb(233, 233, 233);
    font-weight: 500;
    
    font-size: 16px;
    line-height: 28px;
    width: 85%;
    margin: auto;
    margin-top: 40px;
}

.aboutDescription a{
    text-decoration: none;
    /* color: yellow; */
    color: rgb(137, 243, 101);
    font-weight: 600;
}

.aboutButtons{
    margin-top: 70px;
}

.aboutButtons a:first-child{
    text-decoration: none;
    padding: 8px;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    font-size: 15px;
    margin-right: 15px;
}

.aboutButtons a:nth-child(2){
    text-decoration: none;
    padding: 8px;
    padding-left: 20px;
    padding-right: 20px;
    color: black;
    background-color: white;
    border: 1px solid white;
    border-radius: 5px;
    font-size: 15px;
    margin-left: 15px;
}

.aboutExperience{
    margin-bottom: 130px;
}

.aboutExperience h3{
    /* line-height:45px;
    height:45px; */
    width: max-content;
    /* background-color: pink; */
    margin-bottom: 0px;
    margin-top: 0px;
}

.aboutExperienceIndividual h3{
    /* display: flex;
    align-items: center; */
}

.aboutExperiencePosition{
    color: white;
    margin-bottom: 0px;
    margin-top: 0px;
    
}


.aboutExperienceCompany{
    background-color: white;
    height: 45px;
    border-radius: 5px;
    display: inline-block;
    /* margin-left: 5px; */
    white-space: nowrap;
    position: relative;
    /* margin-top: 10px; */
    /* border: 1px solid yellow; */  
}

.aboutExperienceCompanyNew{
    /* color:rgb(255, 255, 82); */
    color: rgb(137, 243, 101);
    /* font-weight: 600; */
    text-decoration: none;
}

.aboutExperienceCompany a{
    /* color: yellow; */
    color: black;
    /* font-weight: 400; */
    font-size: 18px;
    margin-bottom: 0px;
    text-decoration: none;
    margin-right: 10px;
    display: flex;
    
    /* background-color: white; */
}

.aboutExperienceTitle{
    color: white;
}

.aboutExperienceCompany h3{
    /* position: absolute;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    margin: auto; */
    line-height:45px;
    height:45px;
    margin-top: 0px;
    margin-left: 2px;
    padding-right: 0px;
    font-weight: 500;
    font-size: 18px;
}

.aboutEducationInfo{
    /* margin-bottom: 5px; */
}

.aboutEducationInfoSmall{
    display: none;
}

.aboutExperienceCompanySmall{
    display: none;
}

.aboutExperienceCompanyLarge{
    margin-left: 10px;
}

/* .aboutExperienceTitle{

    display: inline-block;
} */

.aboutDescription a:hover, .aboutExperienceCompany a:hover, .aboutExperienceCompanyNew:hover, .aboutEducation a:hover{
    /* background-color: rgb(86, 111, 187); */
    /* background-color: rgb(94, 127, 223);; */
    background-color: rgba(55, 193, 85, .3);
    /* background-color: rgb(95, 95, 0); */
    /* padding: 5px;
    border-radius: 5px; */
    /* padding-left: 5px;
    padding-right: 5px; */
}

.about h4{
    font-weight: 500;
    color: lightgray;
    margin-top: 5px;
    margin-bottom: 15px;
}

.about h5{
    color: white;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
}

.experienceDescription td{
    vertical-align:top
}

.experienceDescription td p{
    margin-top: 0px;
    line-height: 23px;
    margin-bottom: 0px;
    text-align: left;
}


.aboutExperience p{
    font-weight: 500;
}

.circleIcon{
    font-size: 8px;
}

.experienceDescription{
    color: rgb(233, 233, 233);
    font-size: 12px;
    /* background-color: pink; */
    /* width: 100%;; */
    margin-left: 20px;
}

.experienceDescription p{
    display: inline-block;
    font-size: 16px;
    margin-left: 5px;
}

.aboutEducation{
    margin-bottom: 130px;
}

.aboutEducation a{
    font-weight: 500;
    color: rgb(137, 243, 101);
    text-decoration: none;
    font-size: 18px;
}
.aboutEducation h4:nth-child(4){
    display: inline-block;
    margin-right: 10px;
}

.aboutEducation p{
    /* border: 1px solid yellow; */
    /* background-color: rgb(255, 255, 82); */
    font-weight: 500;
    background-color: rgb(55, 193, 85);
    display: inline-block;
    color: rgb(255, 255, 255);
    /* color: white; */
    padding: 6px 10px 6px 10px;
    border-radius: 3px;
    font-size: 15px;
    margin-right: 5px;
    margin-top: 0px;
    margin-bottom: 10px;

}

.aboutSkills{
    margin-bottom: 80px;
}

.aboutSkills h3{
    color: lightgray;
    margin-right: 5px;
    font-size: 16px;
    margin-top: 0px;
}

.aboutSkills p{
    /* color: black;
    display: inline-block;
    background-color: rgb(255, 255, 82);
    padding: 6px 10px 6px 10px;
    border-radius: 5px;
    font-size: 15px;
    margin-right: 10px;
    margin-top: 0px; */
    font-weight: 500;
    background-color: rgb(55, 193, 85);
    display: inline-block;
    color: rgb(255, 255, 255);
    /* color: white; */
    padding: 6px 10px 6px 10px;
    border-radius: 3px;
    font-size: 15px;
    margin-right: 5px;
    margin-top: 0px;
    margin-bottom: 10px;
}

.berkeleyImage{
    width: 250px;
    border-radius: 25px;
    margin-right: 30px;
}

.aboutExperienceLogo{
    /* width: 150px; */
    /* width: 40px; */
    /* padding-bottom: 10px; */
    margin-left: 8px;
    /* background: #3A6F9A; */
    /* margin-top: 3px; */
    /* position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    margin: auto; */
}

.aboutCampusInvolvement p{
    background-color: white;
    border: 1px solid white; 
    color: black;
}

.aboutExperienceIndividual{
    margin-bottom: 30px;
    /* margin-left: 10%; */
}

.aboutEducation h5{
    margin-top: 30px;
}

.aboutEducationInfo p:last-child{
    margin-bottom: 0px;
}

@media screen and (max-width: 850px){
    .about h1{
    }
    .aboutIntroSecond{
        display: none;
    }
    .aboutExperience{
        margin-top: 50px;
        margin-bottom: 90px;
    }
    .aboutEducation{
        margin-bottom: 90px;
    }
    .experienceDescription{
        width: 90%;
    }

    .aboutIntro p{
        width: 95%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .about h1{
        font-size: 24px;
    }
    .about{
        margin-left: 7%;
        margin-right: 7%;
    }
    .experienceDescription{
        margin-left: 5px;
        /* width: calc(100% - 10px); */
        width: calc(100% - 10px);
    }
    /* .aboutExperienceCompanySmall{
        display: inline-block;
    }
    .aboutExperienceCompanyLarge{
        display: none;
    }
    .aboutExperienceTitle{
        text-align: center;
    } */

    .cloudFour{
        display: block;
        top:2500px;
    }


    
}

@media screen and (max-width: 600px){
    .about h2{
        text-align: center;
    }
    .about h5{
        text-align: center;
    }
    .aboutExperienceIndividual{
        margin-bottom: 30px;
        margin-left: 0%;
    }

    .aboutExperience h3 {
        /* text-align: center; */
    }
    .cloudFour{
        display: block;
        top:2750px;
    }


    .aboutExperience{
        /* text-align: center; */
    }
    .aboutExperienceCompanySmall{
        display: inline-block;
    }
    .aboutExperienceCompanyLarge{
        display: none;
    }
    /* .aboutExperienceTitle{
        text-align: center;
    } */
    .aboutExperienceIndividual h3{
        display: block;
        width: 100%;
    }
    .aboutExperiencePosition{
        text-align: center;
        margin-bottom: 10px;
    }
    .aboutEducationInfoSmall{
        display: inline;
        text-align: center;
    }
    .aboutEducationInfoLarge{
        display: none;
    }
    .aboutEducationInfo{
        text-align: center;
    }
   .berkeleyImageDiv{
        width: 100%;
        margin-bottom: 20px;
    }
    .berkeleyImage{
        margin-right: 0px;
        width: 50%;
        min-width: 250px;
    }
    .aboutIntro p{
        line-height: 26px;
    }
}

@media screen and (max-width: 500px){
    .aboutIntroFirst{
        margin-top: 25px;
    }
    .aboutIntro{
        /* margin-bottom: 60px; */

    }
    .experienceDescription{
        margin-left: 0px;
    }
    
}

