.projects{
    margin-left: 15%;
    margin-right: 15%;
    position: relative;
    z-index: 50;
}

.projectsIntro{
    font-size: 28px;
    text-align: center;
    font-weight: 600;
    color: white;
    margin-bottom: 40px;
    margin-top: 20px;
}

.projectFilters h3{
    font-weight: 500;
    display: inline-block;
    padding-right: 5px;
    font-size: 18px;
}

.projectsFiltersDescription{
    color:white;
}

.projectsFiltersTag p{
    display: inline-block;
    background-color: rgb(255, 255, 109);
    padding: 6px 10px 6px 10px;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 10px;
    margin-top: 0px;
}

.projectsFilterType{
    width: 30%;
    /* background-color: pink; */
    display: inline-block;
}

.projectsFilterTags{
    width: 70%;
    /* background-color: green; */
    display: inline-block;
}

.projects h2{
    margin-bottom: 15px;
    color: white;
    font-weight: 600;
    font-size: 22px;
}

.project{
    margin-bottom: 30px;
    border: 2px solid transparent;
    padding-top: 27px;
    padding-bottom: 25px;
    border-radius: 10px;
    /* margin-bottom: 20px; */
}

.project:hover{
    /* border: 1.5px solid rgb(255, 255, 195); */
    border: 1.5px solid rgb(86, 198, 110);
    cursor: pointer;
    /* background-color: rgba(255, 255, 146, 0.15); */
    background-color: rgba(55, 193, 85, 0.15);

    
}

.projectImageDiv{
    width: 45%;
    display: inline-block;
    text-align: center;
}

.projectImageDiv img{
    width: 84%;
    /* max-width: 350px; */

}

.projectInfoDiv{
    width: calc(55% - 44px);
    display: inline-block;
    /* padding-left: 20px; */
    vertical-align: top; 
    padding-left: 10px;   
}

.projectType{
    color: black;
    background-color: white;
    /* color: white;
    background-color: rgb(33, 33, 33); */
    display: inline-block;
    /* background-color: rgb(255, 255, 82); */
    padding: 6px 10px 6px 10px;
    border-radius: 3px;
    font-size: 14px;
    margin-right: 5px;
    margin-top: 0px;
    margin-bottom: 8px;
}

.projectTag{
    /* border: 1px solid yellow; */
    display: inline-block;
    /* color: rgb(36, 36, 36); */
    color: rgb(255, 255, 255);
    font-weight:500;
    /* background-color: rgb(101, 167, 218); */
    /* background-color: rgb(27, 133, 214); */
    /* background-color: rgb(240, 198, 48); */
    /* background-color: rgb(232, 179, 23); */
    background-color: rgb(55, 193, 85);
    /* background-color: rgb(255, 228, 24); */
    padding: 6px 10px 6px 10px;
    border-radius: 3px;
    font-size: 14px;
    margin-right: 5px;
    margin-top: 0px;
    margin-bottom: 10px;
}

.projectInfoDiv h3{
    color: white;
    font-weight: 600;
    font-size: 20px;
    margin-top: 0px;
}

.projectDescription{
    /* background-color: pink; */
    color: white;
    margin-top: 10px;
    margin-bottom: 0px;
    line-height: 23px;
}

.projectTitle{
    display: flex;
}

.projectTitle p{
    color: white;
    width: max-content;
    margin-top: 5px;
    margin-left: 10px;
    font-size: 14px;
}

@media screen and (max-width: 850px){
    .projects{
        margin-left: 8%;
        margin-right: 8%;
    }
    .projectImageDiv{
        width: 86%;
        /* background-color: green; */
        padding-left: 7%;
    }
    .projectInfoDiv{
        /* background-color: pink; */
        width: 86%;
        /* padding-left: 20px; */
        vertical-align: top; 
        padding-left: 7%;
        padding-top: 23px;
    }
    .projectImageDiv img{
        width: 100%;
        max-width: 300px;

    }
    .projectsIntro{
        font-size: 26px;
    }
    .project{
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 850px){
    .projectsIntro{
        font-size: 26px;
    }
}

@media screen and (max-width: 550px){
    .projects{
        margin-left: 4%;
        margin-right: 4%;
    }
    .projectImageDiv{
        width: 86%;
        /* background-color: green; */
        padding-left: 7%;
    }
    .projectInfoDiv{
        /* background-color: pink; */
        width: 86%;
        /* padding-left: 20px; */
        vertical-align: top; 
        padding-left: 7%;
        padding-top: 20px;
    }
    .projectImageDiv img{
        width: 100%;
        max-width: 250px;

    }
    .projectsIntro{
        font-size: 26px;
    }

    .projectTitle{
        display: inline;
    }

    .projectTitle p{
        margin-top: -13px;
        margin-left: 0px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 450px){
    .projects{
        margin-left: 5%;
        margin-right: 5%;
    }
}